/**
 * Hey Listen!
 *
 * @author  Logan Wilkerson | JAMBNC <logan.wilkerson@jambnc.com>
 */

//Core Tools
import {make_signal} from './makers/make_signal';

export {make_signal};
export {make_root_navi} from './makers/make_root_navi';

import * as global_signals_skills from './skills/skills_global_signals';

// Global Signals API
export const make_global_signal = (navi, signal_name) => {
    const signal = make_signal();
    global_signals_skills.globalize_signal(navi, signal_name, signal);
    return signal;
}
export const update_global_value_tracker_signal = (navi, signal_name, value) => {
    const signal = navi.global_signals?.[signal_name] ?? null;
    if (signal) {
        signal.value = value;
    }
    return signal;
}
export const is_global_signal = global_signals_skills.is_global_signal;
export const connect_to_global_signal = global_signals_skills.connect_to_global_signal;
export const emit_global_signal = global_signals_skills.emit_global_signal;

